import React, { Component } from 'react';
import styles from './WorkList.module.scss';
import { Link } from 'react-router-dom';

class WorkList extends Component {

    render(dataSource){
        dataSource = this.props.dataSource;
        return <div className={ styles.worklist }>
        <ul>
            {
                dataSource && dataSource.map(item=>
                    <li key={item.id}>
                       <Link to={ `/work/${item.id}` } style={{ textDecoration: 'none' }}>
                            <div className={ styles.cover }>
                                <img src={ item.cover } />
                            </div>
                            <div className={styles.workbrief}>
                                <h3>{item.title}</h3>
                                <p>{item.brief}</p>
                                <div className={styles.tag}>
                                    { item.tags && item.tags.map(i=><span key={i}>{i}</span>) }
                                </div>
                            </div>                       
                       </Link>
                    </li>
                )
            }
        </ul>
        </div>
    }

}

export default WorkList;