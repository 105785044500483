
import React, { Component } from 'react';
import styles from './WorkDetailPage.module.scss';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

class WorkDetailPage extends Component{

    render(){
        const { id } = this.props.match.params;
        const work = this.props.workData.data[id];

        return (
            <div className={styles.wrap}>
                <div className={styles.box}>
                    <div className={styles.title}>
                        <h1>{work.title}</h1>
                        <p>{work.time}</p>
                    </div>

                    {/* Content */}
                    <div className={styles.content}>
                        {/* left */}
                        <div className={styles.contentleft}>
                            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{work.markdown}</ReactMarkdown>
                        </div>

                        {/* right */}
                        <div className={styles.contentright}>
                            <div>
                                <h1>Links</h1>
                                <ul>
                                    { work.links && Object.keys(work.links).map((key, index)=><li key={index}>
                                            <a href={work.links[key]}>{key}</a>
                                        </li>) }
                                </ul>
                            </div>
                            <div>
                                <h1>Tools</h1>
                                <ul>
                                    { work.tags && work.tags.map(i=><li key={i}>{i}</li>) }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WorkDetailPage;