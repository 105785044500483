
import React, { Component } from 'react';
import styles from './HomePage.module.scss';
import WorkList from '../componnets/WorkList';

class WorkPage extends Component {
    render() {
        return (
            <div className={ styles.wrap }>
                    {/* Selected works */}
                    <section className={ styles.work }>
                        <div className={ styles.title }>
                            <h3>My Work</h3>
                            <p>A collection of different things I have worked on.</p>
                        </div>
                        <WorkList dataSource = { this.props.workData.data } />
                    </section>
                </div>
        )
    }
}

export default WorkPage;

