import styles from './App.module.scss';

import React, { Component } from 'react';
import { Link,Route,withRouter } from 'react-router-dom';

import HomePage from './pages/HomePage'
import WorkPage from './pages/WorkPage';
import WorkDetailPage from './pages/WorkDetailPage';

import workData from './workData.json';

class App extends Component {
  render() {
    const { location } = this.props;

    return (
      <div className={styles.App}>

        {/* Header */}
        <header className={styles.header}>
          <div className={styles.box}>
              <Link to="/" style={{ textDecoration: 'none' }}><div className={styles.boxleft}>Portal</div></Link>
              <div className={styles.boxright}>
                <Link to="/" style={{ textDecoration: 'none' }}><p className={ location.pathname==="/" ? styles.selected : null }>HOME</p></Link>
                <Link to="/work" style={{ textDecoration: 'none' }}><p className={ location.pathname==="/work" ? styles.selected : null } >WORK</p></Link>
              </div>
          </div>
        </header>

        {/* Main */}
        <section className={styles.content}>
          <Route 
            path="/"
            exact
            render={(props) => (
              <HomePage {...props} workData={ workData } />
            )}
          />
          <Route 
            path="/work"
            exact
            render={(props) => (
              <WorkPage {...props} workData={ workData } />
            )}
          />
          <Route 
            path="/work/:id"
            exact
            render={(props) => (
              <WorkDetailPage {...props} workData={ workData } />
            )}
          />
        </section>

        {/* Footer */}
        <footer className={styles.footer}>
          <p>© <b>2021 Minhao Yu</b></p>
          {/* <p>Handmade with</p> */}
        </footer>
      </div>
    );
  }
}

export default withRouter(App);
