
import React, { Component } from 'react';
import styles from './HomePage.module.scss';
import WorkList from '../componnets/WorkList';

class HomePage extends Component {
    render() {
        return (
            <div className={ styles.wrap }>

                    {/* Brief */}
                    <section className={ styles.brief }>
                        <img src="https://i.imgur.com/UU81Jqp.jpg" />
                        <h2>Minhao Yu</h2>
                        <ul>
                            <li>Software Engineer</li>
                            <li>Full-stack Developer</li>
                        </ul>
                        <p>I design applications, interactive systems and websites.</p>
                        <p>Less is more.</p>
                        <p>Contact me at myu30@hawk.iit.edu</p>
                    </section>  

                    {/* Selected works */}
                    <section className={ styles.work }>
                        <div className={ styles.title }>
                            <h3>A Selection of Work</h3>
                            <p>You can find more in the <a href="/work">work overview</a>.</p>
                        </div>
                        <WorkList dataSource = { this.props.workData.data } />
                    </section>

                </div>
        )
    }
}

export default HomePage;

